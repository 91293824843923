import React, { memo, ReactNode, useContext } from 'react';
import styled, { WebTarget } from 'styled-components';
import { Flex } from 'antd';

import { StyleContext } from '../../../../app/providers/lib/StyleContext';

interface UnitProps {
    readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    readonly children: ReactNode;
    readonly gap?: number;
    readonly isCentered?: boolean;
}
export const Unit = memo((props: UnitProps) => {
    const { componentBg } = useContext(StyleContext);

    const { children, onClick, gap, isCentered } = props;

    const unitProps = {
        align: isCentered && 'center',
        vertical: true,
        gap: gap,
        onClick: onClick,
        background: componentBg.hex,
    };

    return <StyledUnit {...unitProps}>{children}</StyledUnit>;
});

const StyledUnit = styled<WebTarget>(Flex)`
    background: ${props => props.background};
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`;
