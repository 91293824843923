import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout } from 'antd';
import { useLaunchParams } from '@telegram-apps/sdk-react';

export const CommonLayout = () => {
    const navigate = useNavigate();
    const launchParams = useLaunchParams(true);

    useEffect(() => {
        if (launchParams) {
            const param = launchParams?.startParam?.split('=')[0];
            const id = launchParams?.startParam?.split('_')[0].split('=')[1];

            if (+id === 0 && param) {
                return navigate('/home');
            }

            if (id && param) {
                return navigate(`/${param}/${id}`);
            }
        }
    }, [launchParams]);
    return (
        <StyledLayout>
            <Outlet />
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    height: 100%;
`;
