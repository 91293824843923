import React, {
    createContext,
    memo,
    PropsWithChildren,
    useEffect,
    useState,
} from 'react';
import { useInitData } from '@telegram-apps/sdk-react';

import { useCreateVisitMutation } from '../../../api/utils.api';

export const DiscountContext = createContext({
    discount: 0,
    isDeliveryEmpty: false,
    loading: false,
});

const DiscountProvider = memo((props: PropsWithChildren) => {
    const { children } = props;

    const [discount, setDiscount] = useState(0);
    const [isDeliveryEmpty, setIsDeliveryEmpty] = useState(false);
    const [loading, setLoading] = useState(false);

    const [createVisit, { data, isLoading }] = useCreateVisitMutation();
    const initData = useInitData(true);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (initData) {
            createVisit({ telegram_user_id: initData?.user.id });
        }
    }, [initData]);

    useEffect(() => {
        if (data) {
            setDiscount(data.discount);
            setIsDeliveryEmpty(data.has_empty_delivery);
        }
    }, [data]);

    return (
        <DiscountContext.Provider
            value={{
                discount: discount,
                isDeliveryEmpty: isDeliveryEmpty,
                loading: loading,
            }}
        >
            {children}
        </DiscountContext.Provider>
    );
});

export { DiscountProvider };
