import React, { memo } from 'react';
import { Flex } from 'antd';
import { gap } from 'app/config/constants';

import { Text } from 'shared/ui/Paragraph/ui/Text';
import { Alert } from 'shared/ui/Icons/Alert';
import { CheckoutErrorHeaderProps } from 'shared/interface/checkout.interface';

export const Header = memo((props: CheckoutErrorHeaderProps) => {
    const { id } = props;

    return (
        <Flex justify={'center'} align={'center'} vertical gap={gap * 3}>
            <Flex vertical gap={gap * 2} align={'center'}>
                <Alert />
                <Flex vertical align={'center'}>
                    <Text size={15}>Oops!</Text>
                    <Text size={15}>Something goes wrong with Wallet!</Text>
                </Flex>
            </Flex>

            <Flex vertical={false} align={'center'} gap={8}>
                <Text type={'secondary'} size={17}>
                    Order number:
                </Text>
                <Text type={'secondary'} size={17}>
                    {id || ''}
                </Text>
            </Flex>
        </Flex>
    );
});
