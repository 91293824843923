import React, { memo, useEffect, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { GetCountries } from 'react-country-state-city';

import {
    FormFieldOption,
    FormFieldProps,
} from 'shared/interface/checkout.interface';

import { useLazyReadCountriesQuery } from '../../../api/utils.api';
import useSessionStorage from '../../../shared/hooks/useSessionStorage';

interface ExternalCountries {
    id: number;
    iso2: string;
    latitude: string;
    longitude: string;
}

export const CountrySelect = memo((props: FormFieldProps) => {
    const { form, values, foreignCallback, foreignValue } = props;
    const [getCountries, { isLoading }] = useLazyReadCountriesQuery();
    const [cacheFields] = useSessionStorage('cacheFields', null);

    const [external, setExternal] = useState([]);
    const [internal, setInternal] = useState([]);

    const [list, setList] = useState([]);

    useEffect(() => {
        GetCountries().then((response: ExternalCountries[]) => {
            setExternal(response);
        });
        getCountries(null, true).then(response => setInternal(response.data));
    }, []);

    useEffect(() => {
        if (external && internal) {
            const newArray = internal
                .map(item => {
                    const found = external.find(
                        arrItem =>
                            arrItem.iso2.toLowerCase() ===
                            item.value.toLowerCase(),
                    );
                    return found
                        ? {
                              value: item.value,
                              label: item.label,
                              price: item.priece,
                              latitude: found.latitude,
                              longitude: found.longitude,
                              id: found.id,
                          }
                        : null;
                })
                .filter(item => item !== null);
            setList(newArray);
        }
    }, [external, internal]);

    const handleSearch = (input: string, option: FormFieldOption) => {
        return (option?.label ?? '')
            .toLowerCase()
            .includes(input?.toLowerCase());
    };

    const sortByLabel = (a: FormFieldOption, b: FormFieldOption) => {
        return (a?.label ?? '')
            .toLowerCase()
            .localeCompare((b?.label ?? '').toLowerCase());
    };

    const handleSelect = (option: FormFieldOption) => {
        if (option.value !== values?.country?.value) {
            form.setFieldValue('state', undefined);
            form.setFieldValue('city', undefined);
        }

        form.setFieldValue('country', option);
    };

    return (
        <StyledSelect
            placeholder={'Select Country/Region'}
            style={{ height: '44px', border: '1px solid', width: '100%' }}
            status={form?.getFieldError('country')?.length > 0 && 'error'}
            loading={isLoading}
            disabled={isLoading}
            showSearch
            filterOption={(input, option) => handleSearch(input, option)}
            filterSort={(optionA, optionB) => sortByLabel(optionA, optionB)}
            value={
                foreignCallback
                    ? cacheFields?.county
                    : form?.getFieldValue('country')
            }
            onSelect={(countryCode, option) => {
                foreignCallback
                    ? foreignCallback(option)
                    : handleSelect(option);
            }}
            defaultValue={foreignCallback && foreignValue}
            variant={'borderless'}
            size={'large'}
            options={list}
        />
    );
});

const StyledSelect = styled(Select)`
    border-radius: 12px;
    background: #1c1c1e;
    height: 44px;
`;
