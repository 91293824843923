import type { RouteObject } from 'react-router';

const routes: RouteObject[] = [
    {
        path: 'legal/:type',
        lazy: async () => {
            const { DocumentsPage } = await import('../../../pages/documents');
            return {
                Component: DocumentsPage,
            };
        },
    },
];

export default routes;
