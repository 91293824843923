import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'antd';

import { gap, routes } from 'app/config/constants';
import Link from 'shared/ui/Link';
import { MoveBottom } from 'shared/ui/Animate';
import { Title } from 'shared/ui/Paragraph';
import { Text } from 'shared/ui/Paragraph/ui/Text';

export const Empty = () => {
    const navigate = useNavigate();
    const handleViewHome = () => {
        navigate(routes.home);
    };

    return (
        <MoveBottom>
            <Flex
                style={{ height: '90vh' }}
                gap={gap * 2}
                vertical
                align={'center'}
                justify={'center'}
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100'
                    height='100'
                    viewBox='0 0 100 100'
                    fill='none'
                >
                    <path
                        d='M14.7886 75.0745L46.7342 92.1536C47.7867 92.7179 48.8701 93 49.9845 93C51.1195 93 52.2236 92.7179 53.2967 92.1536L85.2114 75.0745C87.2132 74.0064 88.6784 72.7772 89.607 71.3866C90.5357 69.9961 91 68.0011 91 65.4014V32.8151C91 30.7797 90.5769 29.0769 89.7308 27.7065C88.9054 26.316 87.5743 25.127 85.7376 24.1395L57.5376 8.96485C55.1024 7.65495 52.5847 7 49.9845 7C47.4049 7 44.8976 7.65495 42.4624 8.96485L14.2624 24.1395C12.4257 25.127 11.0843 26.316 10.2382 27.7065C9.41273 29.0769 9 30.7797 9 32.8151V65.4014C9 68.0011 9.46433 69.9961 10.393 71.3866C11.3423 72.7772 12.8075 74.0064 14.7886 75.0745ZM19.8343 68.3638C18.8024 67.7995 18.0802 67.205 17.6674 66.5803C17.2547 65.9354 17.0483 65.1193 17.0483 64.1318V36.5937L45.8056 52.1009V82.4805L19.8343 68.3638ZM80.1967 68.3638L54.1944 82.4805V52.1009L82.9517 36.5937V64.1318C82.9517 65.1193 82.7453 65.9354 82.3326 66.5803C81.9405 67.205 81.2285 67.7995 80.1967 68.3638ZM50.0155 44.9065L21.5987 29.6411L31.721 24.1093L60.1997 39.4049L50.0155 44.9065ZM68.7433 34.8707L40.2646 19.575L46.1461 16.3708C48.7257 14.9602 51.295 14.9602 53.8539 16.3708L78.4322 29.6411L68.7433 34.8707Z'
                        fill='#636366'
                    />
                </svg>
                <Title level={1} text={'No order history'} />

                <Text
                    type={'secondary'}
                    style={{ textAlign: 'center' }}
                    weight={400}
                    size={17}
                >
                    When you do, you can see your completed orders and returns
                    here
                </Text>

                <Link onClick={handleViewHome} text={'Shop Now'} />
            </Flex>
        </MoveBottom>
    );
};
