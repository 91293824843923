import React, { Fragment, ReactNode } from 'react';
import { Col, Divider, Flex } from 'antd';

import { Text } from 'shared/ui/Paragraph/ui/Text';

import { sizes } from '../lib/options';

interface ComplexCol {
    readonly span: number;
    readonly component?: ReactNode;
    readonly isStart?: boolean;
}
export const SizeTable = () => {
    const RenderColumn = (props: ComplexCol) => {
        const { component, span, isStart } = props;
        return (
            <Col span={span}>
                <Flex justify={isStart ? 'flex-start' : 'flex-end'}>
                    {component}
                </Flex>
            </Col>
        );
    };

    return (
        <>
            <Flex
                vertical={false}
                style={{ marginBottom: '8px', paddingRight: '8px' }}
            >
                <RenderColumn span={3} />

                <RenderColumn
                    span={9}
                    component={
                        <Text
                            type={'secondary'}
                            style={{ textAlign: 'right' }}
                            size={15}
                        >
                            Chest
                        </Text>
                    }
                />

                <RenderColumn
                    span={12}
                    component={
                        <Text
                            type={'secondary'}
                            style={{ textAlign: 'right' }}
                            size={15}
                        >
                            Body length from hps
                        </Text>
                    }
                />
            </Flex>

            {sizes?.map((s, index) => (
                <Fragment key={s.label}>
                    <Divider />
                    <Flex
                        style={{ padding: '16px 8px 16px 0' }}
                        key={s.label}
                        vertical={false}
                    >
                        <RenderColumn
                            isStart
                            span={3}
                            component={<Text size={17}>{s.label}</Text>}
                        />
                        <RenderColumn
                            span={9}
                            component={<Text size={17}>{s.chest}</Text>}
                        />

                        <RenderColumn
                            span={12}
                            component={<Text size={17}>{s.body}</Text>}
                        />
                    </Flex>
                    {index === sizes?.length - 1 ? null : <Divider />}
                </Fragment>
            ))}
        </>
    );
};
