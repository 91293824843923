import { defer, RouteObject } from 'react-router-dom';
import React from 'react';

import { getCategories } from '../../../api/categories.api';

export const routes: RouteObject[] = [
    {
        path: 'home',
        loader: async () => {
            return defer({
                categories: getCategories(),
            });
        },
        lazy: async () => {
            const { HomePage } = await import('../../../pages/home');
            return {
                Component: HomePage,
            };
        },
    },
    {
        path: 'health',
        element: <p>Im healthy</p>,
    },
];
