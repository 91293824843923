import env from '@beam-australia/react-env';

export const Support = 'mzhn_store_sup_bot';
export const Bot = () => {
    const isDev = env('ENVIRONMENT') === 'dev';
    const isLocal = env('ENVIRONMENT') === 'local';

    if (isDev || isLocal) {
        return 'dev_mozhnostore_bot';
    } else return 'mozhnostore_bot';
};

export const WebApp = () => {
    const isDev = env('ENVIRONMENT') === 'dev';
    const isLocal = env('ENVIRONMENT') === 'local';

    if (isDev || isLocal) {
        return 'dev_mozhno';
    } else return 'drops';
};

export const ShopId = () => {
    const isDev = env('ENVIRONMENT') === 'dev';
    const isLocal = env('ENVIRONMENT') === 'local';

    if (isDev || isLocal) {
        return 1;
    } else return 2;
};

export const SellerMail = 'support@mozhno.store';

export const expiredDate = 'September 29, 2024 23:59:59';

export const setExpiredDate = () => {
    //return dayjs().add(1, 'M').format('MMMM D, YYYY HH:MM:ss');
    return expiredDate;
};

export const Delivery = () => {
    const isDev = env('ENVIRONMENT') === 'dev';
    const isLocal = env('ENVIRONMENT') === 'local';

    if (isDev || isLocal) {
        return {
            base: 0.03,
            perPiece: 0.01,
        };
    } else
        return {
            base: 10,
            perPiece: 2,
        };
};

export const currency: { not: string; usd: string } = {
    not: 'NOT',
    usd: '$',
};
