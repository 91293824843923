import env from '@beam-australia/react-env';
import React, { memo, useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { FormFieldProps } from 'shared/interface/checkout.interface';

export const AddressSelect = memo((props: FormFieldProps) => {
    const { form, values } = props;
    const [currentCountry, setCurrentCountry] = useState<string>('');

    const [option, setOption] = useState({
        label: '',
        value: '',
    });

    useEffect(() => {
        if (values) {
            setCurrentCountry(values?.country?.value);
            setOption({
                label: values?.address?.label || '',
                value: values?.address?.value || '',
            });
        }
    }, [values]);

    const onChange = currentOption => {
        setOption({
            label: currentOption.value.structured_formatting.main_text,
            value: currentOption.value.structured_formatting.main_text,
        });

        const data = {
            label: currentOption.value.structured_formatting.main_text,
            value: currentOption.value.structured_formatting.main_text,
        };

        form.setFieldValue('address', data);
    };

    const onInputChange = (currentValue: string) => {
        /* setOption({
            label: currentValue,
            value: currentValue,
        });
        form.setFieldValue('address', option);*/
    };

    return (
        <GooglePlacesAutocomplete
            selectProps={{
                onChange: option => onChange(option),
                autoFocus: true,
                menuIsOpen: true,
                isSearchable: true,
                isDisabled: !values?.city,
                placeholder: !values?.city ? 'Select city' : 'Enter street',
                isClearable: false,
                blurInputOnSelect: true,
                value: option,
                getOptionValue: option => option?.value,
                getOptionLabel: option => option?.label,
                //onBlur: () => null,
                onInputChange: (value, action) => {
                    if (
                        action?.action !== 'input-blur' &&
                        action?.action !== 'menu-close'
                    ) {
                        onInputChange(value);
                    }
                },
                theme: theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',
                        neutral0: '#0F0F0F',
                        neutral20: '#0F0F0F',
                    },
                }),
                styles: {
                    menu: provided => ({
                        background: '#0F0F0F',
                        position: 'absolute',
                        zIndex: 99,
                    }),
                    noOptionsMessage: provided => ({
                        display: 'none',
                        background: '#0F0F0F',
                    }),
                    indicatorsContainer: provided => ({
                        ...provided,
                        display: 'none',
                    }),
                    placeholder: provided => ({
                        ...provided,
                        color: 'rgba(235, 235, 245, 0.30)',
                        padding: '0 16px',
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#ffffff',
                        background: '#1c1c1e',
                        height: '44px',
                        borderRadius: '10px',
                        borderColor: state.isFocused
                            ? '#ffffff'
                            : 'transparent',
                        '&:hover': {
                            borderColor: state.isFocused
                                ? '#ffffff'
                                : 'transparent',
                        },
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        padding: '0 16px',
                        color: '#ffffff',
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                    option: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                        padding: 0,
                        zIndex: 900,
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: '#ffffff',
                        paddingLeft: 16,
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                },
            }}
            apiKey={`${env('GOOGLE_MAPS_KEY')}&loading=async`}
            minLengthAutocomplete={3}
            debounce={500}
            autocompletionRequest={{
                componentRestrictions: {
                    country: currentCountry,
                },
                bounds: [
                    {
                        lat: +values?.city?.latitude,
                        lng: +values?.city?.longitude,
                    },
                    {
                        lat: +values?.city?.latitude,
                        lng: +values?.city?.longitude,
                    },
                ],
                radius: 15000,
                location: {
                    lat: +values?.city?.latitude,
                    lng: +values?.city?.longitude,
                },
            }}
        />
    );
});
