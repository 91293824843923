import httpClient from './index';
import { ShopId } from '../app/config/options';

export const getCategories = async () => {
    return await httpClient.get(`/shops/${ShopId()}/categories`, true);
};

export const getCategory = async (id: number) => {
    return await httpClient.get(`/categories/${id}`, true);
};
