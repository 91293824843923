import React, { createContext, PropsWithChildren } from 'react';

interface LayoutOutcome {
    button: colorProps;
    background: colorProps;
    componentBg: colorProps;
    colorText: colorProps;
    secondaryText: colorProps;
    tertiaryText: colorProps;
    separator: colorProps;
    slider: colorProps;
    error: colorProps;
}

type colorProps = {
    readonly hex: string;
    readonly rgb: string;
};
const defaultColors: LayoutOutcome = {
    button: {
        hex: '#feb803',
        rgb: 'rgb(254 184 3)',
    },
    background: {
        hex: '#0f0f0f',
        rgb: 'rgb(0, 0, 0)',
    },
    componentBg: {
        hex: '#1c1c1e',
        rgb: 'rgb(28, 28, 30)',
    },
    colorText: {
        hex: '#ffffff',
        rgb: 'rgb(255,255,255)',
    },
    secondaryText: {
        hex: '#EBEBF599',
        rgb: 'rgba(235, 235, 245, 60%)',
    },
    tertiaryText: {
        hex: '#EBEBF54D',
        rgb: 'rgba(235 235 245 30%)',
    },
    separator: {
        hex: '#ebebf54c',
        rgb: 'rgba(235,235,245,30%)',
    },
    slider: {
        hex: '#545458',
        rgb: 'rgb(84,84,88)',
    },
    error: {
        hex: '#ff605a',
        rgb: 'rgb(255 96 90)',
    },
};

export const StyleContext = createContext<LayoutOutcome>(defaultColors);

const StyleProvider = ({ children }: PropsWithChildren) => {
    return (
        <StyleContext.Provider value={defaultColors}>
            {children}
        </StyleContext.Provider>
    );
};

export { StyleProvider };
