import React from 'react';

export const Alert = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='94'
            height='94'
            viewBox='0 0 94 94'
            fill='none'
        >
            <path
                d='M42.0064 62.3729C42.0064 63.6973 42.5325 64.9675 43.469 65.904C44.4055 66.8405 45.6757 67.3667 47.0001 67.3667C48.3246 67.3667 49.5947 66.8405 50.5312 65.904C51.4677 64.9675 51.9939 63.6973 51.9939 62.3729C51.9939 61.0485 51.4677 59.7783 50.5312 58.8418C49.5947 57.9053 48.3246 57.3792 47.0001 57.3792C45.6757 57.3792 44.4055 57.9053 43.469 58.8418C42.5325 59.7783 42.0064 61.0485 42.0064 62.3729ZM43.671 30.7458V49.8885C43.671 50.3463 44.0455 50.7208 44.5033 50.7208H49.497C49.9548 50.7208 50.3293 50.3463 50.3293 49.8885V30.7458C50.3293 30.2881 49.9548 29.9135 49.497 29.9135H44.5033C44.0455 29.9135 43.671 30.2881 43.671 30.7458ZM93.1611 76.5219L49.8819 1.61562C49.2369 0.502428 48.1237 -0.0489655 47.0001 -0.0489655C45.8765 -0.0489655 44.7529 0.502428 44.1183 1.61562L0.839153 76.5219C-0.440495 78.7482 1.16167 81.5156 3.72096 81.5156H90.2793C92.8386 81.5156 94.4408 78.7482 93.1611 76.5219ZM11.6485 73.6192L47.0001 12.425L82.3517 73.6192H11.6485Z'
                fill='#636366'
            />
        </svg>
    );
};
