export const routes = {
    home: '/home',
    orders: '/orders',
    cart: '/cart',
};
export const gap = 8;

export const borderRadius = {
    small: '10px',
};
