import React, { CSSProperties, ReactNode } from 'react';
import { Typography } from 'antd';
import styled, { WebTarget } from 'styled-components';

const { Text: AntText } = Typography;

interface TextProps {
    readonly children: ReactNode;
    readonly type?: string;
    readonly size: number;
    readonly center?: boolean;
    readonly weight?: number;
    readonly style?: CSSProperties;
}

export const Text = (props: TextProps) => {
    const { style, children, type, size = 15, center, weight = 500 } = props;
    return (
        <StyledText
            style={style}
            weight={weight}
            size={size}
            type={type}
            $center={center}
        >
            {children}
        </StyledText>
    );
};

const StyledText = styled<WebTarget>(AntText)`
    font-size: ${props => `${props.size}px`};
    text-align: ${props => props.$center && 'center'};
    font-weight: ${props => props.weight};
`;
