import React, { memo } from 'react';
import { Flex } from 'antd';

import { OrderProps } from 'shared/interface/order.interface';
import { Title } from 'shared/ui/Paragraph';
import { Text } from 'shared/ui/Paragraph/ui/Text';

type OrderNumberProps = Pick<OrderProps, 'order_id'>;

export const OrderNumber = memo((props: OrderNumberProps) => {
    const { order_id } = props;

    return (
        <Flex justify={'center'} align={'center'} vertical>
            <Title level={1} text={'Order Details'} />
            <Text type={'secondary'} size={17}>
                Order number: {order_id}
            </Text>
        </Flex>
    );
});
