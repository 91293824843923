import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'antd';

import Link from 'shared/ui/Link';
import { Title } from 'shared/ui/Paragraph';
import { MoveBottom } from 'shared/ui/Animate';
import { Text } from 'shared/ui/Paragraph/ui/Text';
import { gap, routes } from 'app/config/constants';

export const Empty = () => {
    const navigate = useNavigate();
    const handleViewHome = () => {
        navigate(routes.home);
    };

    return (
        <MoveBottom>
            <Flex
                style={{ height: '90vh' }}
                gap={gap * 2}
                vertical
                align={'center'}
                justify={'center'}
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100'
                    height='100'
                    viewBox='0 0 100 100'
                    fill='none'
                >
                    <path
                        d='M25.7259 93C21.5167 93 18.3434 91.9442 16.206 89.8325C14.0687 87.7426 13 84.6078 13 80.428V36.4748C13 32.295 14.0687 29.1602 16.206 27.0703C18.3434 24.9586 21.5167 23.9028 25.7259 23.9028H74.2741C78.4833 23.9028 81.6566 24.9586 83.794 27.0703C85.9313 29.182 87 32.3168 87 36.4748V80.428C87 84.586 85.9749 87.7208 83.9248 89.8325C81.8747 91.9442 78.985 93 75.2555 93H25.7259ZM25.8568 87.2201H75.1574C76.9022 87.2201 78.3416 86.6215 79.4757 85.4241C80.6316 84.2268 81.2095 82.4634 81.2095 80.1341V36.8014C81.2095 34.4502 80.588 32.676 79.3448 31.4787C78.1235 30.2813 76.3896 29.6827 74.1432 29.6827H25.8568C23.5668 29.6827 21.8111 30.2813 20.5897 31.4787C19.3902 32.676 18.7905 34.4502 18.7905 36.8014V80.1341C18.7905 82.4634 19.3902 84.2268 20.5897 85.4241C21.8111 86.6215 23.5668 87.2201 25.8568 87.2201ZM32.9885 24.2294C32.9885 22.0741 33.4138 20.0278 34.2644 18.0903C35.1368 16.131 36.3363 14.4003 37.863 12.8982C39.4114 11.3743 41.2107 10.177 43.2608 9.30619C45.3327 8.4354 47.5791 8 50 8C52.4209 8 54.6563 8.4354 56.7065 9.30619C58.7784 10.177 60.5777 11.3743 62.1043 12.8982C63.6528 14.4003 64.8523 16.131 65.7029 18.0903C66.5753 20.0278 67.0115 22.0741 67.0115 24.2294H61.221C61.221 22.3136 60.7412 20.5394 59.7816 18.9066C58.822 17.2739 57.5025 15.9677 55.8232 14.9881C54.1438 13.9867 52.2028 13.486 50 13.486C47.7972 13.486 45.8562 13.9867 44.1768 14.9881C42.4975 15.9677 41.178 17.2739 40.2184 18.9066C39.2588 20.5394 38.779 22.3136 38.779 24.2294H32.9885Z'
                        fill='#636366'
                    />
                </svg>
                <Title text={'Bag is empty'} level={1} />
                <Text
                    style={{ textAlign: 'center' }}
                    type={'secondary'}
                    size={17}
                >
                    Looks like you have not added anything to your bag yet.
                </Text>
                <Link onClick={handleViewHome} text={'Shop Now'} />
            </Flex>
        </MoveBottom>
    );
};
