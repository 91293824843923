import env from '@beam-australia/react-env';

class HttpClient {
    private API_V1: string = env('GOAPI_URL');
    private API_V3: string = env('API_URL');

    async get(url: string, isGo: boolean) {
        try {
            const response = await fetch(
                `${isGo ? this.API_V1 : this.API_V3}${url}`,
                {},
            );
            return await response.json();
        } catch (e) {
            console.error(`HTTP Client err: ${e}`);
            throw e;
        }
    }

    async post(url: string, isGo: boolean) {
        try {
            const response = await fetch(
                `${isGo ? this.API_V1 : this.API_V3}${url}`,
                { method: 'POST' },
            );
            return await response.json();
        } catch (e) {
            console.error(`HTTP Client err: ${e}`);
            throw e;
        }
    }
}

const httpClient = new HttpClient();

export default httpClient;
