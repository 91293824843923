import { Flex, Image } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useHapticFeedback } from '@telegram-apps/sdk-react';

import payIcon from 'shared/static/icons/pay-with.svg';
import { Text } from 'shared/ui/Paragraph/ui/Text';

type PayBlock = {
    readonly onlyIcon?: boolean;
};
export const PayWith = memo((props: PayBlock) => {
    const { onlyIcon } = props;
    const [count, setCount] = useState(0);
    const hapticFeedback = useHapticFeedback(true);

    const handleChangeCount = () => {
        setCount(count + 1);
    };

    useEffect(() => {
        if (count > 1 && count % 10 === 0 && hapticFeedback) {
            hapticFeedback.notificationOccurred('success');
        }
    }, [count, hapticFeedback]);

    return (
        <Flex vertical={false} align={'flex-start'} gap={5}>
            {onlyIcon ? null : (
                <Text weight={600} size={15}>
                    Pay with
                </Text>
            )}

            <Image onClick={handleChangeCount} src={payIcon} preview={false} />
        </Flex>
    );
});
