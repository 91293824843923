import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    scaleInBottom,
    scaleInTop,
    slideInLeft,
    slideInRight,
} from '../lib/animations';
import { durations } from '../lib/options';

interface AnimationProps {
    children: ReactNode;
    position: string;
}

export const SlideIn: FC<AnimationProps> = ({ children, position }) => {
    const [animation, setAnimation] = useState<any>();

    useEffect(() => {
        if (position) {
            switch (position) {
                case 'top':
                    return setAnimation(scaleInTop);
                case 'bottom':
                    return setAnimation(scaleInBottom);
                case 'right':
                    return setAnimation(slideInRight);
                case 'left':
                    return setAnimation(slideInLeft);
            }
        }
    }, [position]);
    return <StyledAnimation $animation={animation}>{children}</StyledAnimation>;
};

const StyledAnimation = styled.div<{ $animation: string }>`
    animation: ${props => props.$animation} ${durations.slow}
        cubic-bezier(0.23, 1, 0.32, 1) both;
`;
