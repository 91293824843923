import { RouteObject } from 'react-router-dom';
import React from 'react';

import CategoriesFeature from './app/routes/categories';
import ProductFeature from './app/routes/product';
import CheckoutFeature from './app/routes/checkout';
import CartFeature from './app/routes/cart';
import OrdersFeature from './app/routes/order';
import UtilsFeature from './app/routes/utils';
import HomeFeature from './app/routes/home';
import LegalFeature from './app/routes/legal';
import { CommonLayout } from './shared/ui/Layout';

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <CommonLayout />,
        children: [
            ...HomeFeature.routes,
            ...CategoriesFeature.routes,
            ...ProductFeature.routes,
            ...CheckoutFeature.routes,
            ...CartFeature.routes,
            ...OrdersFeature.routes,
            ...LegalFeature.routes,
            ...UtilsFeature.routes,
        ],
    },
];
