import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { fadeIn } from '../lib/animations';
import { durations } from '../lib/options';

export const FadeIn: FC<PropsWithChildren> = ({ children }) => {
    return <StyledAnimation>{children}</StyledAnimation>;
};

const StyledAnimation = styled.div`
    animation: ${fadeIn} ${durations.medium} cubic-bezier(0.23, 1, 0.32, 1);
`;
