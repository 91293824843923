import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { GetState } from 'react-country-state-city';

import {
    FormFieldOption,
    FormFieldProps,
} from 'shared/interface/checkout.interface';

interface StateResponse {
    readonly id: number;
    readonly name: string;
    readonly state_code: string;
}

export const StateSelect = memo((props: FormFieldProps) => {
    const { form, values } = props;

    const [list, setList] = useState([]);

    useEffect(() => {
        if (values?.country?.id) {
            GetState(values?.country?.id).then((result: StateResponse[]) => {
                const states = result.map(state => {
                    return {
                        id: state.id,
                        label: state.name,
                        value: state.name,
                        code: state.state_code.toLowerCase(),
                    };
                });

                setList(states);
            });
        }
    }, [values?.country?.id]);

    const handleSelect = (option: FormFieldOption) => {
        if (option.value !== values?.state?.value) {
            form.setFieldValue('city', undefined);
        }
        form.setFieldValue('state', option);
    };

    const disabled = !values?.country;

    return (
        <StyledSelect
            placeholder={'Select State'}
            style={{ height: '44px' }}
            disabled={disabled}
            status={form?.getFieldError('state')?.length > 0 && 'error'}
            showSearch
            value={form?.getFieldValue('state')}
            onSelect={(countryCode, option) => {
                handleSelect(option);
            }}
            variant={'borderless'}
            size={'large'}
            options={list}
        />
    );
});

const StyledSelect = styled(Select)`
    border-radius: 12px;
    background: #1c1c1e;
    height: 44px;
`;
