import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';

import Actions from 'shared/ui/Actions';
import { MoveBottom } from 'shared/ui/Animate';

import { routes } from '../../../app/config/constants';

const MainButton = lazy(() =>
    import('shared/ui/Button').then(({ MainButton }) => ({
        default: MainButton,
    })),
);
export const Footer = () => {
    const navigate = useNavigate();

    const handleViewHome = () => {
        navigate(routes.home);
    };

    return (
        <Actions>
            <MoveBottom>
                <MainButton onClick={handleViewHome}>
                    Continue Shopping
                </MainButton>
            </MoveBottom>
        </Actions>
    );
};
