import type { RouteObject } from 'react-router';

const routes: RouteObject[] = [
    {
        path: 'block/out-of-stock',
        lazy: async () => {
            const { OutOfStockPage } = await import('../../../pages/utils');
            return {
                Component: OutOfStockPage,
            };
        },
    },
];

export default routes;
