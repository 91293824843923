import React, { memo, useContext } from 'react';
import dayjs from 'dayjs';
import { BaseType } from 'antd/es/typography/Base';
import { Flex } from 'antd';

import { Text } from 'shared/ui/Paragraph/ui/Text';
import { StyleContext } from 'app/providers/lib/StyleContext';

import { dateTemplate } from '../lib/options';

interface DeliveryProps {
    readonly type?: BaseType;
    readonly createdAt?: Date;
}
export const DeliveryDate = memo((props: DeliveryProps) => {
    const { type, createdAt } = props;
    const { colorText, secondaryText } = useContext(StyleContext);

    const renderDeliveryDate = (date: Date) => {
        return dayjs(date).add(1, 'month').format(dateTemplate);
    };

    return (
        <Flex vertical={false} gap={5}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
            >
                <path
                    d='M4.24886 18.0397L11.1409 21.813C11.368 21.9377 11.6018 22 11.8422 22C12.0871 22 12.3252 21.9377 12.5568 21.813L19.4422 18.0397C19.874 17.8038 20.1902 17.5322 20.3905 17.225C20.5909 16.9178 20.691 16.477 20.691 15.9026V8.70334C20.691 8.25366 20.5998 7.87745 20.4172 7.57469C20.2391 7.26749 19.952 7.0048 19.5557 6.78664L13.4717 3.43409C12.9463 3.1447 12.4032 3 11.8422 3C11.2856 3 10.7447 3.1447 10.2193 3.43409L4.13533 6.78664C3.73907 7.0048 3.44968 7.26749 3.26714 7.57469C3.08905 7.87745 3 8.25366 3 8.70334V15.9026C3 16.477 3.10018 16.9178 3.30053 17.225C3.50533 17.5322 3.82144 17.8038 4.24886 18.0397ZM5.33743 16.5571C5.11482 16.4325 4.95899 16.3011 4.86995 16.1631C4.7809 16.0206 4.73638 15.8403 4.73638 15.6221V9.53814L10.9406 12.9641V19.6759L5.33743 16.5571ZM18.3603 16.5571L12.7504 19.6759V12.9641L18.9547 9.53814V15.6221C18.9547 15.8403 18.9101 16.0206 18.8211 16.1631C18.7365 16.3011 18.5829 16.4325 18.3603 16.5571ZM11.8489 11.3747L5.7181 8.00211L7.90193 6.77996L14.046 10.1592L11.8489 11.3747ZM15.8893 9.15747L9.74517 5.77821L11.0141 5.0703C11.5706 4.75864 12.1249 4.75864 12.677 5.0703L17.9796 8.00211L15.8893 9.15747Z'
                    fill={
                        type === 'secondary' ? secondaryText.hex : colorText.hex
                    }
                />
            </svg>

            <Text weight={400} size={15} type={type}>
                Estimated Delivery: {renderDeliveryDate(createdAt)}
            </Text>
        </Flex>
    );
});
