import { useContext } from 'react';

import { CurrencyContext } from '../../app/providers/lib/CurrencyContext';
import { Delivery } from '../../app/config/options';

type CalculateDeliveryOutcomeProps = {
    readonly usd: number;
    readonly not: number;
};

export const usdToNot = (price: number): number => {
    const currency = useContext(CurrencyContext);
    const calcPrice = +price * +currency;
    return Math.ceil(calcPrice);
};

export const calculateDelivery = (
    quantity: number,
): CalculateDeliveryOutcomeProps => {
    const currency = useContext(CurrencyContext);
    const notPrice =
        (Delivery().base + quantity * Delivery().perPiece) * currency;

    return {
        usd: Delivery().base + quantity * Delivery().perPiece,
        not: Math.ceil(+notPrice),
    };
};
