import React, { lazy } from 'react';
import { Flex, Form as AntForm, FormInstance, Input } from 'antd';

import { CheckoutFields } from 'shared/interface/checkout.interface';
import { FormFields } from 'shared/interface/utils.interface';

import { CountrySelect } from './CountrySelect';
import { StateSelect } from './StateSelect';
import { CitySelect } from './CitySelect';

interface FormTypes {
    readonly name: string;
    readonly disabled?: boolean;
    readonly fields: FormFields[];
    readonly form: FormInstance<CheckoutFields>;
    readonly values: CheckoutFields;
}

const ApartmentInput = lazy(() =>
    import('features/Checkout/index').then(({ ApartmentInput }) => ({
        default: ApartmentInput,
    })),
);
const AddressSelect = lazy(() =>
    import('features/Checkout/index').then(({ AddressSelect }) => ({
        default: AddressSelect,
    })),
);

export const Form = (props: FormTypes) => {
    const { name, form, disabled, fields, values } = props;

    const inputProps = {
        form: form,
        values: values,
    };

    const renderField = (field: FormFields) => {
        switch (field.name) {
            case 'country':
                return <CountrySelect {...inputProps} />;
            case 'state':
                return <StateSelect {...inputProps} />;
            case 'city':
                return <CitySelect {...inputProps} />;
            case 'address':
                return <AddressSelect {...inputProps} />;
            case 'apartment':
                return <ApartmentInput field={field} {...inputProps} />;

            default:
                return (
                    <Input
                        style={{
                            background: '#1C1C1E',
                        }}
                        variant='outlined'
                        {...field.input}
                    />
                );
        }
    };

    return (
        <AntForm
            form={form}
            scrollToFirstError
            name={name + '_form'}
            labelAlign='left'
            layout={'vertical'}
            disabled={disabled}
            requiredMark={false}
        >
            <Flex vertical gap={16}>
                {fields
                    .sort((a, b) => a.position - b.position)
                    .map(field => (
                        <AntForm.Item
                            style={{ fontWeight: 600 }}
                            key={field.name}
                            label={field.label}
                            name={field.name}
                            rules={field.rules}
                            hidden={field?.hidden}
                        >
                            {renderField(field)}
                        </AntForm.Item>
                    ))}
            </Flex>
        </AntForm>
    );
};
