import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { getOrder, getOrders } from '../../../api/order.api';

const routes: RouteObject[] = [
    {
        path: 'orders',
        loader: async () => {
            return defer({
                orders: getOrders(),
            });
        },
        lazy: async () => {
            const { OrdersPage } = await import('../../../pages/order');
            return {
                Component: OrdersPage,
            };
        },
    },
    {
        path: 'orders/:id',
        loader: async ({ params }) => {
            return defer({
                order: getOrder(+params.id),
            });
        },
        lazy: async () => {
            const { OrderPage } = await import('../../../pages/order');
            return {
                Component: OrderPage,
            };
        },
    },
];

export default routes;
