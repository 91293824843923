import React, { memo, useContext, useEffect } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';

import { buildFilePath } from 'shared/lib/buildFilePath';
import { getCoverImage } from 'shared/lib/getCoverImage';
import { ProductListProps } from 'shared/interface/product.interfce';
import { Discount, Tag } from 'shared/ui/Discount';
import { FormattedPrice } from 'shared/ui/Price';
import { Text } from 'shared/ui/Paragraph/ui/Text';
import { LayoutContext } from 'app/providers/lib/LayoutContext';
import { DiscountContext } from 'app/providers/lib/DiscountContext';
import { currency } from 'app/config/options';
import { usdToNot } from 'shared/lib/finance';

export const List = memo((props: ProductListProps) => {
    const { id, images, title, price, onClick } = props;

    const { colWidth } = useContext(LayoutContext);
    const { discount } = useContext(DiscountContext);
    const elementRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const ele = entry.target as HTMLElement;
                    const src = ele.getAttribute('data-background-src');

                    if (src) {
                        ele.removeAttribute('data-background-src');
                        ele.style.backgroundImage = `url(${src})`;
                        observer.unobserve(ele);
                    }
                }
            });
        });
        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, [elementRef]);

    return (
        <StyledWrapper vertical gap={12} onClick={() => onClick(+id)}>
            <StyledImage
                ref={elementRef}
                $colWidth={+Math.ceil(colWidth).toFixed(2)}
                data-background-src={buildFilePath(getCoverImage(images))}
            >
                <Tag isShort />
            </StyledImage>

            <Flex vertical gap={4}>
                <Text weight={400} size={15}>
                    {title}
                </Text>

                {discount > 0 ? (
                    <Discount.Catalog discount={discount} price={price} />
                ) : (
                    <Flex vertical>
                        <Text type={'secondary'} weight={600} size={15}>
                            <FormattedPrice value={usdToNot(price)} />{' '}
                            {currency.not}
                        </Text>
                        <Text type={'secondary'} weight={400} size={15}>
                            ≈ {currency.usd}
                            <FormattedPrice value={price} />
                        </Text>
                    </Flex>
                )}
            </Flex>
        </StyledWrapper>
    );
});

const StyledWrapper = styled(Flex)`
    cursor: pointer;
`;

const StyledImage = styled.div<{ $colWidth: number }>`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 10px;
    padding: 6px;
    width: ${props => `${Math.ceil(props.$colWidth - 8).toFixed(2)}px`};
    height: ${props => `${Math.ceil(props.$colWidth * 1.18).toFixed(2)}px`};
`;
