import { retrieveLaunchParams } from '@telegram-apps/sdk';
import qs from 'query-string';

import httpClient from './index';
import { OrderProps } from '../shared/interface/order.interface';
import { ShopId } from '../app/config/options';

export const getOrder = async (id: number) => {
    return await httpClient.get(`/market/show/order/${id}`, false);
};

export const getOrders = async (): Promise<OrderProps[]> => {
    const launchParams = retrieveLaunchParams();

    const params = {
        shop_id: ShopId(),
        telegram_user_id: launchParams.initData.user.id,
    };

    const queryArgs = qs.stringify(params, {
        arrayFormat: 'bracket',
        skipNull: true,
    });

    return await httpClient.get(
        `/market/show/orders/history?${queryArgs}`,
        false,
    );
};
