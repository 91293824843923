export const statusList = [
    'Counting coins, not vibes... Hold tight!',
    'Stacking up your digital dough. Almost there!',
    'Making your wallet as lit as your feed. Just a sec!',
    'Money moves loading... Stay woke!',
    'Turning zeros into hero coins. Hang on!',
    "Ready to flex those gains? We're almost done!",
    "Your crypto is cooking! Don't touch that dial.",
    'Brb, just securing your financial glow-up.',
    'No cap, this is about to be fire. Loading...',
    'Getting your digital stack ready to slay the day!',
];
