import React, { lazy } from 'react';

import { Text } from 'shared/ui/Paragraph/ui/Text';
import { SlideIn } from 'shared/ui/Animate/ui/SlideIn';

const Wrapper = lazy(() =>
    import('shared/ui/Layout').then(({ Wrapper }) => ({
        default: Wrapper,
    })),
);
const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);

export const Header = () => {
    return (
        <SlideIn position={'right'}>
            <Wrapper position={'first'}>
                <Unit>
                    <Text center size={15} type={'secondary'}>
                        Please make sure that these fields are filled in
                        correctly to ensure accurate processing and timely
                        delivery of your order.
                    </Text>
                </Unit>
            </Wrapper>
        </SlideIn>
    );
};
