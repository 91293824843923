import { useUtils } from '@telegram-apps/sdk-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import {
    useCreateBuyMutation,
    useCreateRepeatedBuyMutation,
} from '../../api/checkout.api';
import {
    CheckoutFields,
    PaymentSystems,
} from '../interface/checkout.interface';
import { ShopId } from '../../app/config/options';
import { CartProps } from '../interface/cart.interface';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
import { routes } from '../../app/config/constants';

interface usePaymentOutcome {
    makePayment: (
        paymentSystem: PaymentSystems,
        cacheFields: CheckoutFields,
        cartProps: CartProps[],
        id: number | undefined,
    ) => void;
    readonly isLoading: boolean;
}

export const usePayments = (isRepeated: boolean): usePaymentOutcome => {
    const { id } = useParams();
    const utils = useUtils(true);
    const navigate = useNavigate();

    const [createBuy, { isLoading, isSuccess, isError, data, error }] =
        isRepeated ? useCreateRepeatedBuyMutation() : useCreateBuyMutation();

    useEffect(() => {
        if (isError && error) {
            if (error.data.data.redirect === '') {
                return id || error?.data?.order_id
                    ? navigate(
                          `/checkout/checkout-error/${id || error?.data?.order_id}`,
                      )
                    : navigate(routes.orders);
            }
        }
    }, [isError, error]);

    useIsomorphicLayoutEffect(() => {
        if (isSuccess && data) {
            if (data.redirect.length > 2) {
                if (data.redirect?.includes('changelly')) {
                    try {
                        const iframe = document.createElement('iframe');
                        iframe.src = data.redirect;
                        iframe.style.border = '0';
                        iframe.style.width = '100%';
                        iframe.style.height = '100vh';
                        iframe.style.position = 'absolute';
                        iframe.style.top = '0';
                        iframe.style.zIndex = '999';
                        document.body.appendChild(iframe);

                        id || data?.order_id
                            ? navigate(`/status/${id || data?.order_id}`)
                            : navigate(routes.orders);
                    } catch (e) {
                        /* empty */
                    }
                } else {
                    try {
                        utils.openTelegramLink(data.redirect);
                        id || data?.order_id
                            ? navigate(`/status/${id || data?.order_id}`)
                            : navigate(routes.orders);
                    } catch (e) {
                        /* empty */
                    }
                }
            }
        }
    }, [isSuccess, data]);

    const handleRePayOrder = async (
        telegram_user_id: number,
        order_id: number,
        payment_system: PaymentSystems,
    ): Promise<void> => {
        const payload = {
            telegram_user_id: telegram_user_id,
            order_id: order_id,
            payment_system: payment_system,
        };

        await createBuy(payload);
    };

    const handlePayOrder = async (
        paymentSystem: PaymentSystems,
        cacheFields: CheckoutFields,
        cart: any,
        userId: number,
    ) => {
        const extraPayload = {
            country: cacheFields?.country?.label,
            state: cacheFields?.state?.label,
            city: cacheFields?.city?.label,
            address: cacheFields?.address?.label,
            apartment: cacheFields?.apartment,
            postal_code: cacheFields?.postal,
            street: cacheFields?.address?.label,
            building: cacheFields?.apartment,
            telegram_user_id: userId,
            first_name: cacheFields?.firstName,
            last_name: cacheFields?.lastName,
            //full_name: `${cacheFields?.firstName || ''} ${cacheFields?.lastName || ''}`,
            phone: cacheFields?.phone?.replace(/\s+/g, ''),
        };

        const payload = {
            telegram_user_id: userId,
            product_id_list: cart.map(
                (p: { product_id: number }) => p.product_id,
            ),
            shop_id: ShopId(),
            payment_system: paymentSystem,
            ...extraPayload,
        };
        await createBuy(payload);
    };

    return {
        makePayment: isRepeated ? handleRePayOrder : handlePayOrder,
        isLoading: isLoading,
    };
};
