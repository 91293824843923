import React, { CSSProperties } from 'react';
import { Typography } from 'antd';

const { Title: AntTitle } = Typography;

interface TitleProps {
    readonly text: string;
    readonly level: 1 | 2 | 3;
    readonly style?: CSSProperties;
}
export const Title = (props: TitleProps) => {
    const { text, level, style } = props;
    return (
        <AntTitle style={style} level={level}>
            {text}
        </AntTitle>
    );
};
