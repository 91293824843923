import React, { useContext } from 'react';
import styled, { WebTarget } from 'styled-components';
import { Flex, Image } from 'antd';

import loader from 'shared/static/images/navigation-loader-white.svg';
import { gap } from 'app/config/constants';

import { StatusLoader } from './StatusLoader';
import { StyleContext } from '../../../../app/providers/lib/StyleContext';

export const NavigationLoader = () => {
    const { background } = useContext(StyleContext);

    return (
        <StyledWrapper
            align={'center'}
            justify={'center'}
            vertical
            gap={gap * 4}
            indent={gap}
            background={background.hex}
        >
            <StyledAnimationWrapper $indent={gap}>
                <Image
                    style={{ maxWidth: '83px' }}
                    src={loader}
                    preview={false}
                />
            </StyledAnimationWrapper>
            <StatusLoader />
        </StyledWrapper>
    );
};

const StyledWrapper = styled<WebTarget>(Flex)`
    z-index: 200;
    background: ${props => props.background};
    height: 100vh;
`;

const StyledAnimationWrapper = styled.div<{ $indent: number }>`
    padding: ${props => `0 ${props.$indent * 14}px`};
`;
