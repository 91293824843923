import React, { useContext } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';

import { DiscountContext } from 'app/providers/lib/DiscountContext';

import { Wings } from '../../Icons/Wings';
import { Caption } from '../../Paragraph/ui/Caption';

interface TagProps {
    readonly isShort?: boolean;
}
export const Tag = (props: TagProps) => {
    const { isShort } = props;
    const { discount } = useContext(DiscountContext);

    return discount > 0 ? (
        <StyledTag justify={'center'} gap={2} align={'center'}>
            <Wings />
            <Caption type={'warning'} size={10}>
                {isShort ? `-${discount}%` : `${discount}% discount`}
            </Caption>
        </StyledTag>
    ) : (
        <></>
    );
};

const StyledTag = styled(Flex)`
    padding: 4px 6px;
    border-radius: 5px;
    background: rgba(84, 84, 88, 0.65);
    backdrop-filter: blur(2px);
`;
