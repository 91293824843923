import React, { lazy, Suspense } from 'react';
import { Await, useAsyncValue, useLoaderData } from 'react-router-dom';
import { Flex } from 'antd';

import { OrderProps } from 'shared/interface/order.interface';
import { NavigationLoader } from 'shared/ui/Loaders';
import { Changelly } from 'shared/ui/Icons/Changelly';
import { Wallet } from 'shared/ui/Icons/Wallet';
import { usePayments } from 'shared/hooks/usePayments';
import { Content, Header } from 'wigets/checkoutError';
import { gap } from 'app/config/constants';

const MainButton = lazy(() =>
    import('shared/ui/Button').then(({ MainButton }) => ({
        default: MainButton,
    })),
);
const Wrapper = lazy(() =>
    import('shared/ui/Layout').then(({ Wrapper }) => ({
        default: Wrapper,
    })),
);
const Page = lazy(() =>
    import('shared/ui/Layout').then(({ Page }) => ({
        default: Page,
    })),
);
const Screen = lazy(() =>
    import('shared/ui/Layout').then(({ Screen }) => ({
        default: Screen,
    })),
);

const CheckoutErrorPageLoaded = () => {
    const { data } = useAsyncValue() as Awaited<{ data: OrderProps }>;
    const { makePayment, isLoading } = usePayments(true);

    return isLoading ? (
        <NavigationLoader />
    ) : (
        <Wrapper position={'first'}>
            <Flex vertical gap={gap * 3}>
                <Header id={data.order_id || null} />
                <Content item={data} />

                <Page>
                    <MainButton
                        onClick={() =>
                            makePayment(
                                data?.telegram_user_id,
                                data?.order_id,
                                'cryptoWallet',
                            )
                        }
                    >
                        Try again Wallet <Wallet />
                    </MainButton>
                    <MainButton
                        onClick={() =>
                            makePayment(
                                data?.telegram_user_id,
                                data?.order_id,
                                'changelly',
                            )
                        }
                    >
                        Try again Crypto <Changelly />
                    </MainButton>
                </Page>
            </Flex>
        </Wrapper>
    );
};
export const CheckoutErrorPage = () => {
    const { order } = useLoaderData() as { order: OrderProps };

    return (
        <Screen>
            <Suspense fallback={<NavigationLoader />}>
                <Await resolve={order} errorElement={<NavigationLoader />}>
                    <CheckoutErrorPageLoaded />
                </Await>
            </Suspense>
        </Screen>
    );
};
