import React from 'react';

import { AntdFixesStyle } from 'shared/theme/antdFixes';

import { GlobalStyle } from './globalStyles';
import { SliderStyles } from './SliderStyles';

export const Styles = () => {
    const styles = [SliderStyles, GlobalStyle, AntdFixesStyle];

    return (
        <>
            {styles.map((Component, index) => (
                <Component key={index} />
            ))}
        </>
    );
};
