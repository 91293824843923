import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { VisitResponse } from '../shared/interface/order.interface';
import { Countries } from '../shared/interface/utils.interface';
import { Currency } from '../shared/interface/product.interfce';

export const utilsApi = createApi({
    reducerPath: 'utilsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('API_URL'),
    }),
    tagTypes: ['CommonApi'],
    endpoints: builder => ({
        createVisit: builder.mutation({
            query: body => ({
                url: `/shop/visit`,
                method: 'POST',
                body,
            }),
            transformResponse: (res: { data: VisitResponse }) => {
                return res.data;
            },
        }),
    }),
});

export const { useCreateVisitMutation } = utilsApi;

export const utilsGoApi = createApi({
    reducerPath: 'utilsGoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('GOAPI_URL'),
    }),
    endpoints: builder => ({
        readCurrency: builder.query<Currency, void>({
            keepUnusedDataFor: 3600,
            query: () => ({
                method: 'GET',
                url: `/exchangerate`,
            }),
            transformResponse: (response: { data: Currency }) => {
                return response.data;
            },
        }),
        readCountries: builder.query<Countries[], number>({
            keepUnusedDataFor: 3600,
            query: () => {
                return {
                    url: `/delivery-list`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: Countries[] }) => {
                return res.data;
            },
        }),
    }),
});

export const { useReadCurrencyQuery, useLazyReadCountriesQuery } = utilsGoApi;
