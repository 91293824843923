import React, { memo } from 'react';
import dayjs from 'dayjs';
import { Flex } from 'antd';

import { gap } from 'app/config/constants';
import Arrow from 'shared/ui/Icons/Arrow';
import { OrderProps } from 'shared/interface/order.interface';
import { Warning } from 'shared/ui/Icons/Warning';
import { Text } from 'shared/ui/Paragraph/ui/Text';

export const List = memo((props: OrderProps) => {
    const {
        order_id,
        status_name,
        created_at,
        product_list,
        is_filled_delivery,
    } = props;

    return (
        <Flex vertical gap={gap * 2}>
            <Flex vertical={false} justify={'space-between'} align={'center'}>
                <Flex vertical>
                    <Flex align={'center'} vertical={false} gap={gap - 2}>
                        <Text weight={600} size={17}>
                            Order: {order_id}
                        </Text>
                        {!is_filled_delivery && <Warning />}
                    </Flex>
                    <Text type={'secondary'} weight={400} size={15}>
                        Date: {dayjs(created_at).format('MMM D, YYYY')}
                    </Text>
                </Flex>
                <Flex
                    vertical={false}
                    align={'center'}
                    justify={'center'}
                    style={{ marginLeft: 'auto' }}
                    gap={gap}
                >
                    <Text type={'secondary'} size={17}>
                        {product_list?.length} items
                    </Text>
                    <Arrow position={'right'} />
                </Flex>
            </Flex>

            <Flex vertical={false} gap={gap / 2}>
                <Text weight={400} type={'secondary'} size={15}>
                    Status:
                </Text>

                <Text weight={700} size={15}>
                    {status_name}
                </Text>
            </Flex>
        </Flex>
    );
});
