import React, { lazy, memo } from 'react';

import { Title } from 'shared/ui/Paragraph';
import { CartUnit } from 'features/Cart';

import { ProductList } from '../../../shared/interface/order.interface';

interface ProductsListTypes {
    readonly items: ProductList[];
}

const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);
const Page = lazy(() =>
    import('shared/ui/Layout').then(({ Page }) => ({
        default: Page,
    })),
);

export const ProductsList = memo((props: ProductsListTypes) => {
    const { items } = props;

    return (
        <Page>
            <Title text={'Items'} level={2} />
            {items?.map(product => (
                <Unit key={product.id}>
                    <CartUnit {...product} />
                </Unit>
            ))}
        </Page>
    );
});
