import React, { lazy, Suspense, useEffect } from 'react';
import { Await, useAsyncValue, useLoaderData } from 'react-router-dom';
import { useInitData } from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';
import { Flex } from 'antd';

import { DeliveryInfo } from 'wigets/orders/ui/DeliveryInfo';
import { SummaryInfo } from 'wigets/orders/ui/SummaryInfo';
import Placeholder from 'shared/ui/Placeholder';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { NavigationLoader } from 'shared/ui/Loaders';
import { OrderProps } from 'shared/interface/order.interface';
import { ProductsList } from 'wigets/orders';
import { Footer, Header } from 'wigets/checkoutStatus';

const Wrapper = lazy(() =>
    import('shared/ui/Layout').then(({ Wrapper }) => ({
        default: Wrapper,
    })),
);
const Screen = lazy(() =>
    import('shared/ui/Layout').then(({ Screen }) => ({
        default: Screen,
    })),
);

const CheckoutStatusPageLoaded = () => {
    const { data } = useAsyncValue() as Awaited<{ data: OrderProps }>;
    const [, setCart] = useSessionStorage('cart', []);

    useEffect(() => {
        if (data) {
            setCart([]);
        }
    }, [data]);

    return (
        <>
            <Wrapper position={'first'}>
                <Flex vertical gap={24}>
                    <Header {...data} />
                    <DeliveryInfo {...data} {...data?.delivery} />
                    <ProductsList items={data?.product_list} />
                    <SummaryInfo {...data} />
                </Flex>
            </Wrapper>
            <Placeholder />
            <Footer />
        </>
    );
};
export const CheckoutStatusPage = () => {
    const { order } = useLoaderData() as { order: OrderProps };

    const initData = useInitData(true);

    useEffect(() => {
        if (initData) {
            ReactTagManager.action({
                event: 'success',
                telegram_id: initData?.user.id,
            });
        }
    }, [initData]);

    return (
        <Screen hideBack>
            <Suspense fallback={<NavigationLoader />}>
                <Await resolve={order} errorElement={<NavigationLoader />}>
                    <CheckoutStatusPageLoaded />
                </Await>
            </Suspense>
        </Screen>
    );
};
