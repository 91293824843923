import React, { useContext } from 'react';
import { Flex, Image } from 'antd';
import { gap } from 'app/config/constants';

import bodyMeasurements from 'shared/static/icons/size-tooltip.svg';
import { Text } from 'shared/ui/Paragraph/ui/Text';

import { LayoutContext } from '../../../app/providers/lib/LayoutContext';
import { measurements } from '../lib/options';

export const SizeTooltip = () => {
    const { wrapperWidth } = useContext(LayoutContext);

    return (
        <Flex vertical gap={gap * 2} style={{ marginBottom: '8px' }}>
            <Text type={'secondary'} size={12}>
                *The table shows the measurements of the item of clothing (not
                body parameters).
            </Text>

            <Text size={17} weight={600}>
                Нow to take measurements?
            </Text>

            {measurements.map((measure, index) => (
                <Flex gap={gap} vertical key={index}>
                    <Text size={15}>
                        {index + 1}. {measure.title}
                    </Text>
                    <Text size={15}>{measure.text}</Text>
                </Flex>
            ))}

            <Flex justify={'center'} style={{ padding: '0 16px' }}>
                <Image
                    style={{ width: wrapperWidth - 32 }}
                    preview={false}
                    src={bodyMeasurements}
                />
            </Flex>
        </Flex>
    );
};
