import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { getProduct } from '../../../api/product.api';

const routes: RouteObject[] = [
    {
        path: 'product/:id',
        loader: async ({ params }) => {
            return defer({
                product: getProduct(+params.id),
            });
        },
        lazy: async () => {
            const { ProductPage } = await import('../../../pages/product');
            return {
                Component: ProductPage,
            };
        },
    },
];

export default routes;
