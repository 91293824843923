import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';

import { gap } from 'app/config/constants';

import { FormattedPrice } from '../../Price';
import { Text } from '../../Paragraph';
import { usdToNot } from '../../../lib/finance';
import { currency } from '../../../../app/config/options';

type DiscountProps = {
    readonly price: number;
    readonly discount: number;
};

interface DiscountComponent extends React.FC<PropsWithChildren<unknown>> {
    Product: React.FC<DiscountProps>;
    Catalog: React.FC<DiscountProps>;
    Cart: React.FC<DiscountProps>;
    CartUnit: React.FC<DiscountProps>;
}

export const Discount: DiscountComponent = ({ children }) => {
    const subComponents = React.Children.map(children, child => {
        return React.isValidElement(child) ? React.cloneElement(child) : null;
    });

    return (
        <StyledDiscount>
            {subComponents?.map((component, index) => (
                <React.Fragment key={index}>{component}</React.Fragment>
            ))}
        </StyledDiscount>
    );
};

const StyledDiscount = styled.div`
    text-decoration: line-through;
`;
const Product: React.FC<DiscountProps> = ({ price, discount }) => (
    <Flex vertical>
        <Text size={20} weight={700}>
            <FormattedPrice
                value={usdToNot(price - (price / 100) * discount)}
            />{' '}
            {currency.not}
        </Text>
        <Text
            type={'secondary'}
            style={{
                textDecoration: 'line-through',
            }}
            weight={400}
            size={15}
        >
            {usdToNot(price)} {currency.not}
        </Text>
        <Text type={'secondary'} weight={400} size={15}>
            ≈ $<FormattedPrice value={price - (price / 100) * discount} />
        </Text>
    </Flex>
);

Discount.Product = Product;

const Catalog: React.FC<DiscountProps> = ({ price, discount }) => (
    <>
        <Flex gap={4} align={'center'}>
            <Text size={15} weight={600} type={'secondary'}>
                <FormattedPrice
                    value={usdToNot(price - (price / 100) * discount)}
                />{' '}
                {currency.not}
            </Text>

            <Text
                style={{
                    textDecoration: 'line-through',
                    color: '#515154',
                }}
                size={12}
                weight={400}
            >
                <FormattedPrice value={usdToNot(price)} /> {currency.not}
            </Text>
        </Flex>
        <Text type={'secondary'} weight={400} size={15}>
            ≈ $<FormattedPrice value={price - (price / 100) * discount} />
        </Text>
    </>
);
Discount.Catalog = Catalog;

const Cart: React.FC<DiscountProps> = ({ price, discount }: DiscountProps) => (
    <Flex gap={4} justify={'space-between'}>
        <Text type={'secondary'} size={15}>
            {`Discount ${discount}%`}
        </Text>
        <Text size={15} weight={400}>
            – {usdToNot((price / 100) * discount)} {currency.not}
        </Text>
    </Flex>
);
Discount.Cart = Cart;

const CartUnit: React.FC<DiscountProps> = ({
    price,
    discount,
}: DiscountProps) => (
    <Flex gap={gap} justify={'flex-start'} align={'center'}>
        <Text weight={500} size={15}>
            <FormattedPrice
                value={usdToNot(price - (price / 100) * discount)}
            />{' '}
            {currency.not}
        </Text>
        <Text
            style={{
                lineHeight: '22px',
                textDecoration: 'line-through',
                color: '#515154',
            }}
            weight={400}
            size={12}
        >
            <FormattedPrice value={usdToNot(price)} /> {currency.not}
        </Text>
    </Flex>
);
Discount.CartUnit = CartUnit;
