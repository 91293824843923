import React, { memo } from 'react';
import { Flex, Image } from 'antd';

import { CartUnitProps } from 'shared/interface/cart.interface';
import { buildFilePath } from 'shared/lib/buildFilePath';
import { Discount } from 'shared/ui/Discount';
import { Caption } from 'shared/ui/Paragraph/ui/Caption';
import { Text } from 'shared/ui/Paragraph/ui/Text';
import { FormattedPrice } from 'shared/ui/Price';
import { usdToNot } from 'shared/lib/finance';
import { currency } from 'app/config/options';
import { gap } from 'app/config/constants';

export const CartUnit = memo((props: CartUnitProps) => {
    const {
        title,
        price,
        options,
        quantity,
        image,
        category_name,
        hideQuantity,
        discount,
        size,
    } = props;

    return (
        <Flex vertical={false} gap={gap * 2}>
            <Image
                preview={false}
                style={{ borderRadius: 10, width: gap * 12 }}
                src={buildFilePath(image)}
            />
            <Flex vertical>
                <Caption weight={600} type={'secondary'}>
                    {category_name}
                </Caption>

                <Text size={15} weight={400}>
                    {title}
                </Text>

                <Flex vertical={false} gap={gap}>
                    {hideQuantity ? null : (
                        <Text size={15}>Qty: {quantity}</Text>
                    )}
                    {options && (
                        <Text size={15}>Size: {size || options?.size}</Text>
                    )}
                </Flex>

                {discount > 0 ? (
                    <Discount.CartUnit discount={discount} price={price} />
                ) : (
                    <Text weight={500} size={15}>
                        <FormattedPrice value={usdToNot(price)} />{' '}
                        {currency.not}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
});
