import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';

import { List } from 'features/Order';
import { OrderContentProps } from 'shared/interface/order.interface';
import { FadeIn } from 'shared/ui/Animate';

const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);

export const Content = (props: OrderContentProps) => {
    const { item } = props;
    const navigate = useNavigate();

    const handleViewOrder = (orderId: number) => {
        navigate(`${orderId}`);
    };

    return (
        <FadeIn>
            <Unit onClick={() => handleViewOrder(item.order_id)}>
                <List {...item} />
            </Unit>
        </FadeIn>
    );
};
