import env from '@beam-australia/react-env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const checkoutApi = createApi({
    reducerPath: 'checkoutApi',
    baseQuery: fetchBaseQuery({
        baseUrl: env('API_URL'),
    }),
    tagTypes: ['Checkout'],
    endpoints: builder => ({
        createBuy: builder.mutation({
            query: body => ({
                url: `/market/product/buy`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: {
                data: { order_id: number; redirect: string };
            }) => {
                return response.data;
            },
            invalidatesTags: ['Checkout'],
        }),
        createRepeatedBuy: builder.mutation({
            query: body => ({
                url: `/market/product/pay_order`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: { data: { redirect: string } }) => {
                return response.data;
            },
            invalidatesTags: ['Checkout'],
        }),
        updateCart: builder.mutation<void, any>({
            query: body => ({
                url: `/market/card/update`,
                method: 'POST',
                body,
            }),
        }),
        createDelivery: builder.mutation({
            query: body => ({
                url: `/market/product/order/make-delivery`,
                method: 'POST',
                body,
            }),
            transformResponse: response => {
                return response;
            },
            invalidatesTags: ['Checkout'],
        }),
    }),
});

export const {
    useCreateBuyMutation,
    useCreateRepeatedBuyMutation,
    useUpdateCartMutation,
    useCreateDeliveryMutation,
} = checkoutApi;
