import React from 'react';
import { Flex, Typography } from 'antd';
import { useUtils } from '@telegram-apps/sdk-react';

import { gap } from 'app/config/constants';
import { SellerMail, Support } from 'app/config/options';
import { Text } from 'shared/ui/Paragraph/ui/Text';

import { ReturnPolicyText, ShippingPolicyText } from '../lib/options';

export const DeliveryReturn = () => {
    const utils = useUtils(true);

    const handleView = () => {
        utils.openTelegramLink(`https://t.me/${Support}`);
    };

    return (
        <Flex vertical gap={16} style={{ marginTop: gap * 2 }}>
            <Flex vertical gap={gap}>
                <Text size={17}>Shipping Policy</Text>
                <Text type={'secondary'} size={15}>
                    {ShippingPolicyText}
                </Text>
            </Flex>

            <Flex vertical gap={gap}>
                <Text size={17}>Return Policy</Text>
                <Text type={'secondary'} size={15}>
                    {ReturnPolicyText}
                    <Typography.Link
                        target={'_blank'}
                        href={`mailto:${SellerMail}`}
                    >
                        {' '}
                        {SellerMail}{' '}
                    </Typography.Link>
                    or our Telegram bot{' '}
                    <Typography.Link onClick={handleView}>
                        @{Support}
                    </Typography.Link>
                </Text>
            </Flex>
        </Flex>
    );
};
