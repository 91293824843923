import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';

import { gap } from 'app/config/constants';

import { Text } from '../../Paragraph';
import { statusList } from '../lib/options';

export const StatusLoader = () => {
    const [statusText, setStatusText] = useState(statusList[0]);
    const [, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => {
                const newIndex = (prevIndex + 1) % statusList.length;
                setStatusText(statusList[newIndex]);
                return newIndex;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    const centered = {
        justify: 'center',
        align: 'center',
    };

    return (
        <Flex vertical gap={gap} {...centered}>
            <Text center weight={500} size={15}>
                {statusText}
            </Text>
        </Flex>
    );
};
