import React, { lazy, ReactNode } from 'react';

const BackButton = lazy(() =>
    import('shared/ui/Button').then(({ BackButton }) => ({
        default: BackButton,
    })),
);

export const Screen = ({
    children,
    hideBack,
}: {
    children: ReactNode;
    hideBack?: boolean;
}) => {
    return (
        <>
            {children}
            <BackButton isHide={hideBack} />
        </>
    );
};
