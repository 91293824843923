import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { getProducts } from '../../../api/product.api';
import { getCategory } from '../../../api/categories.api';

const routes: RouteObject[] = [
    {
        path: 'categories/:mainId',
        id: 'category',
        loader: async ({ params }) => {
            return defer({
                products: getProducts(+params.mainId),
                category: getCategory(+params.mainId),
            });
        },
        lazy: async () => {
            const { CategoriesPage } = await import(
                '../../../pages/categories'
            );
            return {
                Component: CategoriesPage,
            };
        },
    },
];

export default routes;
