import type { RouteObject } from 'react-router';
import { defer, Outlet } from 'react-router-dom';

import { CheckoutPage } from '../../../pages/checkout';
import { getOrder } from '../../../api/order.api';

const routes: RouteObject[] = [
    {
        path: 'checkout',
        element: <Outlet />,
        children: [
            { path: '', element: <CheckoutPage /> },
            {
                path: 'checkout-error/:id',
                loader: async ({ params }) => {
                    return defer({
                        order: getOrder(+params.id),
                    });
                },
                lazy: async () => {
                    const { CheckoutErrorPage } = await import(
                        '../../../pages/checkout'
                    );
                    return {
                        Component: CheckoutErrorPage,
                    };
                },
            },
        ],
    },
    {
        path: 'status/:id',
        loader: async ({ params }) => {
            return defer({
                order: getOrder(+params.id),
            });
        },
        lazy: async () => {
            const { CheckoutStatusPage } = await import(
                '../../../pages/checkout'
            );
            return {
                Component: CheckoutStatusPage,
            };
        },
    },
];

export default routes;
