import React, { CSSProperties, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useLaunchParams } from '@telegram-apps/sdk-react';
import { gap } from 'app/config/constants';

type Actions = {
    readonly children?: React.ReactNode;
    readonly style?: CSSProperties;
};

export const Actions = memo((props: PropsWithChildren<Actions>) => {
    const { children, style } = props;
    const launchParams = useLaunchParams(true);

    return (
        <StyledActions style={style}>
            <StyledWrapper
                $gap={launchParams?.platform === 'ios' ? gap * 3 : gap * 2}
            >
                {children}
            </StyledWrapper>
        </StyledActions>
    );
});

const StyledActions = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
`;

const StyledWrapper = styled.div<{ $gap: number }>`
    padding: ${props => `${props.$gap}px`};
`;
