import React, { FC, lazy, memo, PropsWithChildren } from 'react';
import { Divider, Flex } from 'antd';
import dayjs from 'dayjs';

import { gap } from 'app/config/constants';
import { OrderProps } from 'shared/interface/order.interface';
import { Title } from 'shared/ui/Paragraph';
import { Text } from 'shared/ui/Paragraph/ui/Text';
import { StringOrNumber } from 'shared/interface/utils.interface';
import { usdToNot } from 'shared/lib/finance';
import { currency } from 'app/config/options';
import { FormattedPrice } from 'shared/ui/Price';

const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);
const Page = lazy(() =>
    import('shared/ui/Layout').then(({ Page }) => ({
        default: Page,
    })),
);

export const SummaryInfo = memo((props: OrderProps) => {
    const {
        order_id,
        created_at,
        product_list,
        status_name,
        total,
        delivery_sum,
        discount_sum,
        subtotal,
        discount,
    } = props;

    const renderUnitTitle = (title: string) => {
        return (
            <Text type={'secondary'} size={15}>
                {title}
            </Text>
        );
    };

    const renderUnitContent = (
        content: StringOrNumber,
        currency?: string,
        extra?: string,
    ) => {
        return (
            <Text weight={400} size={15}>
                {extra}{' '}
                {currency ? <FormattedPrice value={+content} /> : content}{' '}
                {currency}
            </Text>
        );
    };

    const renderTotal = (title: string, content: number) => {
        const textOptions = {
            size: 17,
            weight: 600,
        };

        return (
            <>
                <Text {...textOptions}>{title}</Text>

                <Flex vertical align={'flex-end'}>
                    <Text {...textOptions}>
                        <FormattedPrice value={usdToNot(+content)} />{' '}
                        {currency.not}
                    </Text>
                    <Text size={15} weight={400} type={'secondary'}>
                        ≈ {currency.usd}
                        <FormattedPrice value={content} />
                    </Text>
                </Flex>
            </>
        );
    };

    const UnitWrapper: FC<PropsWithChildren> = ({ children }) => {
        return (
            <Flex justify={'space-between'} align={'center'}>
                {children}
            </Flex>
        );
    };

    return (
        <Page>
            <Title text={'Summary'} level={2} />
            <Unit gap={gap}>
                <UnitWrapper>
                    {renderUnitTitle('Order number')}
                    {renderUnitContent(order_id)}
                </UnitWrapper>
                <UnitWrapper>
                    {renderUnitTitle('Date')}
                    {renderUnitContent(dayjs(created_at).format('MMM D, YYYY'))}
                </UnitWrapper>

                {product_list?.length > 0 && (
                    <UnitWrapper>
                        {renderUnitTitle('Items')}
                        {renderUnitContent(`${product_list?.length}`)}
                    </UnitWrapper>
                )}
                <UnitWrapper>
                    {renderUnitTitle('Status')}
                    {renderUnitContent(`${status_name}`)}
                </UnitWrapper>

                {status_name === 'Canceled' ? null : (
                    <>
                        <Divider style={{ margin: `${gap * 2}px 0` }} />
                        <UnitWrapper>
                            {renderUnitTitle('Subtotal')}
                            {renderUnitContent(
                                usdToNot(subtotal),
                                currency.not,
                            )}
                        </UnitWrapper>

                        {discount_sum > 0 && (
                            <UnitWrapper>
                                {renderUnitTitle(
                                    `Discount ${discount?.split('.')?.[0]}%`,
                                )}
                                {renderUnitContent(
                                    usdToNot(discount_sum),
                                    currency.not,
                                    '–',
                                )}
                            </UnitWrapper>
                        )}

                        <UnitWrapper>
                            {renderUnitTitle('Delivery')}
                            {renderUnitContent(
                                usdToNot(delivery_sum),
                                currency.not,
                            )}
                        </UnitWrapper>
                        <UnitWrapper>
                            {renderTotal('Total', +total)}
                        </UnitWrapper>
                    </>
                )}
            </Unit>
        </Page>
    );
});
