import React, { PropsWithChildren, Suspense } from 'react';
import { SDKProvider } from '@telegram-apps/sdk-react';
import { App as Antd } from 'antd';

import { ExtraProviders } from './app/providers';
import TelegramInit from './TelegramInit';
import { Styles } from './shared/theme/Styles';

const App = (props: PropsWithChildren) => {
    return (
        <Suspense>
            <SDKProvider acceptCustomStyles>
                <TelegramInit />
                <ExtraProviders>
                    <Antd>
                        <Styles />
                        {props.children}
                    </Antd>
                </ExtraProviders>
            </SDKProvider>
        </Suspense>
    );
};

export default App;
