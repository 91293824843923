import React from 'react';

import { CheckoutErrorContentProps } from 'shared/interface/checkout.interface';

import { DeliveryInfo, ProductsList } from '../../orders';

export const Content = (props: CheckoutErrorContentProps) => {
    const { item } = props;

    return (
        <>
            <ProductsList items={item?.product_list} />
            <DeliveryInfo {...item} {...item?.delivery} />
        </>
    );
};
