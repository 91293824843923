import React, { memo, useContext } from 'react';
import { Flex, Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';

import { StyleContext } from 'app/providers/lib/StyleContext';

interface LinkTypes {
    readonly onClick: () => void;
    readonly text: string;
    readonly isSimple?: boolean;
    readonly size?: number;
    readonly type?: BaseType;
    readonly isUnderline?: boolean;
}

export const Link = memo((props: LinkTypes) => {
    const { onClick, text, isSimple, size, type, isUnderline } = props;
    const { button, colorText } = useContext(StyleContext);

    return (
        <Flex vertical={false} align={'center'} gap={8} onClick={onClick}>
            <Typography.Link
                type={type}
                style={{
                    fontSize: size || 17,
                    textDecoration: isUnderline ? 'underline' : 'none',
                    textUnderlineOffset: '3px',
                }}
            >
                {text}
            </Typography.Link>
            {isSimple ? null : (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='11'
                    height='20'
                    viewBox='0 0 11 20'
                    fill='none'
                >
                    <path
                        d='M11.5 10C11.5 10.1732 11.4684 10.3345 11.4053 10.4839C11.3479 10.6272 11.2533 10.7646 11.1213 10.8961L2.40219 19.6685C2.18988 19.8895 1.9288 20 1.61894 20C1.41236 20 1.223 19.9492 1.05086 19.8477C0.884455 19.7461 0.749609 19.6057 0.646322 19.4265C0.548774 19.2533 0.5 19.0562 0.5 18.8351C0.5 18.5185 0.611894 18.2407 0.835681 18.0018L8.82316 10L0.835681 1.98925C0.611894 1.7503 0.5 1.47252 0.5 1.15591C0.5 0.94086 0.548774 0.746714 0.646322 0.573477C0.749609 0.400239 0.884455 0.262843 1.05086 0.16129C1.223 0.0537634 1.41236 0 1.61894 0C1.9288 0 2.18988 0.110514 2.40219 0.331541L11.1213 9.10394C11.2533 9.23536 11.3479 9.37276 11.4053 9.51613C11.4684 9.6595 11.5 9.82079 11.5 10Z'
                        fill={type === 'warning' ? button.hex : colorText.hex}
                    />
                </svg>
            )}
        </Flex>
    );
});
