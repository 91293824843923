import React from 'react';

export const Wings = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='16'
            viewBox='0 0 20 16'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.8967 2.15024C15.0981 1.73502 15.3977 1.4092 15.6258 1.2457C16.8063 2.40334 15.8991 3.83036 15.2111 4.04C15.3144 4.30687 15.4079 4.56885 15.4916 4.8264C15.8907 4.35547 16.4876 4.11813 16.8593 4.05659C17.2829 5.26417 16.5407 6.02199 15.859 6.24224C15.906 6.49098 15.9422 6.73591 15.9676 6.97751C16.4793 6.50453 17.1993 6.33888 17.6012 6.34988C17.8009 7.70868 16.7408 8.34456 15.9893 8.37829C15.9631 8.70766 15.9141 9.03217 15.8416 9.35322C16.4392 9.06528 17.1329 9.08868 17.503 9.19567C17.3508 10.598 16.1039 10.9283 15.3743 10.7401C15.2646 10.9833 15.1405 11.2257 15.0018 11.4679C15.6777 11.3306 16.372 11.5543 16.7089 11.7673C16.1657 13.086 14.8535 13.0403 14.2127 12.6441C14.0124 12.9049 13.7949 13.1668 13.5598 13.4304C14.2695 13.4382 14.9144 13.8445 15.2008 14.1407C14.3358 15.3296 13.0349 14.9029 12.5438 14.3342C11.8604 14.0719 10.9728 12.3162 12.35 11.3143C12.6458 11.6003 13.098 12.3249 13.1406 13.0921C13.3209 12.8689 13.4894 12.6486 13.6463 12.4305C13.0043 12.0364 12.378 10.927 13.2603 9.84028C13.6262 10.0443 14.1912 10.5898 14.3674 11.2994C14.4976 11.0639 14.614 10.8301 14.7171 10.5973C13.9875 10.4118 13.0308 9.46824 13.6431 8.15766C14.0711 8.27326 14.8081 8.70574 15.1273 9.42022C15.2149 9.0756 15.2741 8.73069 15.3062 8.38322C14.6768 8.62685 13.2658 7.98297 13.4576 6.34363C13.9165 6.31757 14.8929 6.61346 15.3188 7.27481C15.2967 6.94148 15.2518 6.60379 15.1852 6.25984C15.1516 6.24592 15.1206 6.22903 15.0928 6.20914L15.0917 6.19924C14.4486 6.44317 13.1758 6.09878 12.9515 4.62363C13.3908 4.47602 14.272 4.53629 14.8787 5.06955C14.7901 4.79004 14.6892 4.5055 14.5764 4.21503C14.01 4.60494 12.6857 4.56836 12.1244 3.18088C12.4364 2.98632 13.0493 2.8547 13.6455 2.99758C13.0246 2.48924 12.7976 1.67638 12.8823 0.57696C13.9604 0.937496 14.6196 1.44443 14.8967 2.15024Z'
                fill='#FEB803'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.49441 2.15024C4.29298 1.73502 3.99344 1.4092 3.76535 1.2457C2.58483 2.40334 3.49205 3.83036 4.18 4.04C4.07673 4.30687 3.98319 4.56885 3.89954 4.8264C3.50044 4.35547 2.90347 4.11813 2.53177 4.05659C2.10823 5.26417 2.8504 6.02199 3.5321 6.24224C3.48513 6.49098 3.44887 6.73591 3.42348 6.97751C2.91184 6.50453 2.19177 6.33888 1.78989 6.34988C1.59026 7.70868 2.65033 8.34456 3.40181 8.37829C3.42799 8.70766 3.47706 9.03217 3.54948 9.35322C2.95186 9.06528 2.25819 9.08868 1.88808 9.19567C2.04035 10.598 3.28719 10.9283 4.01679 10.7401C4.12647 10.9833 4.25058 11.2257 4.38934 11.4679C3.71339 11.3306 3.01908 11.5543 2.68225 11.7673C3.22542 13.086 4.53759 13.0403 5.17844 12.6441C5.3787 12.9049 5.59622 13.1668 5.83127 13.4304C5.12161 13.4382 4.47674 13.8445 4.19029 14.1407C5.05528 15.3296 6.35625 14.9029 6.84733 14.3342C7.53066 14.0719 8.41827 12.3162 7.04114 11.3143C6.74528 11.6003 6.29309 12.3249 6.25054 13.0921C6.07018 12.8689 5.90169 12.6486 5.74479 12.4305C6.38683 12.0364 7.01309 10.927 6.13083 9.84028C5.76492 10.0443 5.19993 10.5898 5.02375 11.2994C4.89353 11.0639 4.77709 10.8301 4.67403 10.5973C5.40366 10.4118 6.36028 9.46824 5.74802 8.15766C5.32 8.27326 4.58299 8.70574 4.26382 9.42022C4.17625 9.0756 4.11703 8.73069 4.08493 8.38322C4.71426 8.62685 6.12533 7.98297 5.93352 6.34363C5.47463 6.31757 4.49817 6.61346 4.07228 7.27481C4.09444 6.94148 4.13931 6.60379 4.20588 6.25984C4.23953 6.24592 4.27047 6.22903 4.29827 6.20914L4.2994 6.19924C4.94254 6.44317 6.21532 6.09878 6.43966 4.62363C6.00032 4.47602 5.11907 4.53629 4.51245 5.06955C4.60102 4.79004 4.70194 4.5055 4.81474 4.21503C5.38112 4.60494 6.70543 4.56836 7.26668 3.18088C6.95469 2.98632 6.34183 2.8547 5.74557 2.99758C6.36647 2.48924 6.59353 1.67638 6.50879 0.57696C5.43074 0.937496 4.77149 1.44443 4.49441 2.15024Z'
                fill='#FEB803'
            />
        </svg>
    );
};
