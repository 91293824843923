import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { GetCity } from 'react-country-state-city';

import {
    FormFieldOption,
    FormFieldProps,
} from 'shared/interface/checkout.interface';

interface CityResponse {
    readonly id: number;
    readonly name: string;
    readonly latitude: string;
    readonly longitude: string;
}

export const CitySelect = memo((props: FormFieldProps) => {
    const { form, values } = props;
    const [list, setList] = useState([]);

    useEffect(() => {
        if (values?.country?.id && values?.state?.id) {
            GetCity(values?.country.id, values?.state.id).then(
                (result: CityResponse[]) => {
                    const cities = result.map(city => {
                        return {
                            id: city.id,
                            label: city.name,
                            value: city.name,
                            latitude: city.latitude,
                            longitude: city.longitude,
                        };
                    });

                    setList(cities);
                },
            );
        }
    }, [values?.country?.id, values?.state?.id]);

    const handleSelect = (option: FormFieldOption) => {
        form.setFieldValue('address', null);
        form.setFieldValue('city', option);
    };

    const disabled = !values?.country || !values?.state;
    return (
        <StyledSelect
            style={{ height: '44px' }}
            placeholder={'Select City'}
            disabled={disabled}
            status={form?.getFieldError('city')?.length > 0 && 'error'}
            showSearch
            value={form?.getFieldValue('city')}
            onSelect={(countryCode, option) => {
                handleSelect(option);
            }}
            variant={'borderless'}
            size={'large'}
            options={list}
        />
    );
});

const StyledSelect = styled(Select)`
    border-radius: 12px;
    background: #1c1c1e;
    height: 44px;
`;
