import React, { lazy } from 'react';
import { Flex } from 'antd';

import Actions from 'shared/ui/Actions';
import { MoveBottom } from 'shared/ui/Animate';

const MainButton = lazy(() =>
    import('shared/ui/Button').then(({ MainButton }) => ({
        default: MainButton,
    })),
);

interface FooterProps {
    readonly orderId: number;
    readonly onClick: () => void;
    readonly isSubmittable: boolean;
}

export const Footer = (props: FooterProps) => {
    const { orderId, onClick, isSubmittable } = props;

    return (
        <Actions>
            <MoveBottom>
                <Flex vertical={false} align={'center'} gap={24}>
                    <MainButton onClick={onClick}>
                        {orderId || isSubmittable ? 'Continue' : 'Back'}
                    </MainButton>
                </Flex>
            </MoveBottom>
        </Actions>
    );
};
