import React from 'react';
import { Input } from 'antd';

import { FormFieldProps } from 'shared/interface/checkout.interface';
import { FormFields } from 'shared/interface/utils.interface';

interface ApartmentInputProps extends FormFieldProps {
    readonly field: FormFields;
}
export const ApartmentInput = (props: ApartmentInputProps) => {
    const { form, field, values } = props;

    const onChange = (value: string) => {
        form.setFieldValue('apartment', value);
        form.setFieldValue('building', value);
    };

    return (
        <Input
            disabled={!values?.city}
            value={form?.getFieldValue('apartment')}
            onChange={e => {
                onChange(e.target.value);
            }}
            style={{
                background: '#1C1C1E',
            }}
            variant='outlined'
            {...field.input}
        />
    );
};
