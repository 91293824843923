import React from 'react';
import { useUtils } from '@telegram-apps/sdk-react';
import { Flex } from 'antd';

import { Support } from 'app/config/options';
import { MoveTop } from 'shared/ui/Animate';
import { Title } from 'shared/ui/Paragraph';
import Link from 'shared/ui/Link';

export const Header = () => {
    const utils = useUtils(true);

    const handleContactSeller = async () => {
        const link = `https://t.me/${Support}`;
        utils.openTelegramLink(link);
    };

    return (
        <MoveTop>
            <Flex justify={'space-between'}>
                <Title text={'Orders'} level={2} />
                <Link onClick={handleContactSeller} text={'Contact Support'} />
            </Flex>
        </MoveTop>
    );
};
