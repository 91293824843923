import { combineReducers } from 'redux';

import { checkoutApi } from '../../api/checkout.api';
import { utilsApi, utilsGoApi } from '../../api/utils.api';

const rootReducer = combineReducers({
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
    [utilsGoApi.reducerPath]: utilsGoApi.reducer,
});

export default rootReducer;
