import React, { lazy, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { gap } from 'app/config/constants';

import { Text } from '../../Paragraph';
import { Link } from '../../Link/ui/Link';
import { CountrySelect } from '../../../../features/Checkout';
import useSessionStorage from '../../../hooks/useSessionStorage';

export enum EmptyAddressProps {
    cart = 'cart',
    status = 'status',
    order = 'order',
}

export interface EmptyProps {
    readonly page: EmptyAddressProps;
    readonly orderId?: number;
    readonly cacheFields: any;
    readonly setCacheFields: any;
}

const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);

export const Empty = (props: EmptyProps) => {
    const { page, orderId, cacheFields, setCacheFields } = props;
    const navigate = useNavigate();

    const [foreignValue, setForeignValue] = useState(cacheFields?.country);

    const handleCountrySelect = option => {
        setForeignValue(option);
        setCacheFields({ ...cacheFields, country: option });
    };

    const renderEmpty = (currentPage: EmptyAddressProps) => {
        switch (currentPage) {
            case EmptyAddressProps.cart:
                return (
                    <>
                        <Text center size={15} type={'warning'}>
                            {`We'll need your address to get the order delivered. Please be sure to fill in the country field. The rest can be filled in later`}
                        </Text>
                        <CountrySelect
                            foreignValue={foreignValue}
                            foreignCallback={handleCountrySelect}
                        />
                    </>
                );
            case EmptyAddressProps.status:
                return (
                    <Text center size={15} type={'warning'}>
                        Fill in the address so that the order will arrive!
                    </Text>
                );
            case EmptyAddressProps.order:
                return (
                    <Text center size={15} type={'warning'}>
                        Please fill in your address so we can ensure the order
                        is delivered to you.
                    </Text>
                );
        }
    };

    const handleAction = () => {
        if (orderId) {
            navigate(`/checkout?orderId=${orderId}`);
        } else navigate('/checkout');
    };

    return (
        <Unit isCentered gap={gap * 2}>
            {renderEmpty(page)}

            <Link
                onClick={handleAction}
                type={'warning'}
                text={'Add Address'}
            />
        </Unit>
    );
};
