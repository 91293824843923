import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import styled, { WebTarget } from 'styled-components';

const { Text: AntText } = Typography;

interface CaptionProps {
    readonly children: ReactNode;
    readonly size?: number;
    readonly type?: string;
    readonly lh?: number;
    readonly weight?: number;
}
export const Caption = (props: CaptionProps) => {
    const { children, type, size = 13, lh = 1.53, weight = 500 } = props;
    return (
        <StyledCaption weight={weight} lh={lh} size={size} type={type}>
            {children}
        </StyledCaption>
    );
};

const StyledCaption = styled<WebTarget>(AntText)`
    font-size: ${props => `${props.size}px`};
    line-height: ${props => `${props.lh}`};
    font-weight: ${props => props.weight};
`;
