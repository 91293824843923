import React, { memo } from 'react';
import parse from 'html-react-parser';

import { StringOrNull } from 'shared/interface/utils.interface';
import { Text } from 'shared/ui/Paragraph/ui/Text';

interface DescriptionProps {
    text: StringOrNull;
}

export const Description = memo((props: DescriptionProps) => {
    const { text } = props;

    return (
        <div>
            <Text size={15} type={'secondary'}>
                {text ? parse(text) : 'This product has no description.'}
            </Text>
        </div>
    );
});
