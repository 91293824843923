import { createGlobalStyle } from 'styled-components';

export const AntdFixesStyle = createGlobalStyle`

    .ant-badge .ant-badge-count {
        color: #ffffff;
    }

    .ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
        width: 100%;
    }
    
    .ant-select-outlined:focus-within{
        box-shadow: none !important;

    }
    .ant-select-outlined:focus {
        box-shadow: none !important;

    }

    .ant-input-outlined:focus {
        box-shadow: none !important;
    }
    .ant-input-outlined:focus-within {
        box-shadow: none !important;
    }

    .ant-input-outlined  {
        box-shadow: none;
    }
`;
