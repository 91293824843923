import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { slideInRight } from '../lib/animations';
import { durations } from '../lib/options';

export const SlideInRight: FC<PropsWithChildren> = ({ children }) => {
    return <StyledAnimation>{children}</StyledAnimation>;
};

const StyledAnimation = styled.div`
    animation: ${slideInRight} ${durations.fast} cubic-bezier(0.25, 1, 0.35, 1)
        both;
`;
