import type { RouteObject } from 'react-router';

const routes: RouteObject[] = [
    {
        path: 'cart',
        lazy: async () => {
            const { CartPage } = await import('../../../pages/cart');
            return {
                Component: CartPage,
            };
        },
    },
];

export default routes;
