import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import { checkoutApi } from '../../api/checkout.api';
import { utilsApi, utilsGoApi } from '../../api/utils.api';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            checkoutApi.middleware,
            utilsApi.middleware,
            utilsGoApi.middleware,
        ]),
});
